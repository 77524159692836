<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-information</v-icon>
            マスタ管理・メンテナンス 
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    メンテナンス設定 
                </v-card-subtitle>
                <v-spacer />
                <v-btn
                    large
                    :loading="saveLoading"
                    :disabled="saveLoading"
                    class="btn-edit ma-2"
                    @click="clickSave"
                    >保存</v-btn
                >
                <v-btn large class="btn-close ma-2" @click="clickBack"
                    >もどる</v-btn
                >
            </v-system-bar>
        </v-card>
		<v-card class="mx-5 mt-10 pa-10">
			<div class="text-caption">稼働ステータス</div>
			<div class="mainte-status-box">
				<v-radio-group v-model="status" hide-details="auto" row>
					<v-radio v-for="item in status_radio" 
						:label="item.name" 
						:value="item.val" 
						:key="item.val">
					</v-radio>
				</v-radio-group>
			</div>
			<div class="text-caption">時間指定</div>
			<div class="mainte-time-area">
				<div class="d-flex d-inline-flex">
					<input-date-fld :date.sync="startDate" :disabled="status!=2?true:false" :error="isError(err.startDate)"></input-date-fld>
					<vue-timepicker v-model="startTime" :disabled="status!=2?true:false" class="mainte-timepic pl-2" :class="[isError(err.startTime)?'err':'normal']"></vue-timepicker>
					<div class="mx-6 text-h5">～</div>
					<input-date-fld :date.sync="endDate" :disabled="status!=2?true:false" :error="isError(err.endDate)"></input-date-fld>
					<vue-timepicker v-model="endTime" :disabled="status!=2?true:false" class="mainte-timepic pl-2" :class="[isError(err.endTime)?'err':'normal']"></vue-timepicker>
				</div>
				<div
					class="controll-error ml-2"
					v-show="isError(err.startDate)"
				>
					<span v-html="dispError(err.startDate)"></span>
				</div>
				<div
					class="controll-error ml-2"
					v-show="isError(err.endDate)"
				>
					<span v-html="dispError(err.endDate)"></span>
				</div>
				<div
					class="controll-error ml-2"
					v-show="isError(err.startTime)"
				>
					<span v-html="dispError(err.startTime)"></span>
				</div>
				<div
					class="controll-error ml-2"
					v-show="isError(err.endTime)"
				>
					<span v-html="dispError(err.endTime)"></span>
				</div>
			</div>
			<div class="text-caption">メンテナンスメッセージ</div>
			<div class="mainte-time-area">
				<v-textarea style="width:700px" 
				hide-details="auto"
				v-model="message"
				outlined
				:error="isError(err.message)"
				:error-messages="err.message"
				label="メンテンス画面で表示する内容をご入力下さい" solo></v-textarea>
			</div>
			
		</v-card>	

	</v-container>
</template>
<style lang="scss">
.mainte-time-area{
	padding:10px;
	margin:10px;
	border:1px solid #ccc;
	width:800px;
}
.mainte-status-box{
	padding:10px;
	margin:10px;
	width:400px;
	border: 1px solid #ccc;
	border-radius: 5px !important;
}
.mainte-timepic {
	width:100px;
	input{
		border-radius: 5px !important;
		width:100px !important;
		height: 32px !important;
	}
	.normal>input{
		border:1px solid #9e9e9e !important;
	}
	span.clear-btn{
		padding-top: 5px !important;
		height: 32px;
		color: #757575;
		font-size:23px;
	 }
}
.mainte-timepic.normal input{
	border:1px solid #9e9e9e !important;
}
.mainte-timepic.err input{
	border:2px solid red!important;
}
</style>
<script>
import InputDateFld from "../components/InputDateFld.vue";
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import {MESSAGE} from "../messages";
import {CONSTANT} from "../constant";

export default {
    components: { InputDateFld, VueTimepicker },
	data() {
		return {
			status:'1',
			startDate:'',
			startTime:{HH:'',mm:''},
			endDate:'',
			endTime:{HH:'',mm:''},
			message:'',
			absolute:true,
			err:{
				status:'',
				startDate:'',
				startTime:'',
				endDate:'',
				endTime:'',
				message:'',
			},
            saveLoading: false,
			status_radio: [
				{name:'稼働中', val:CONSTANT.KADO_STATUS_KADO},
				{name:'停止', val:CONSTANT.KADO_STATUS_TEISHI},
				{name:'時間指定', val:CONSTANT.KADO_STATUS_JIKAN},
				]
		}
	},
	computed:{
	},
	created(){
		this.getMaintenance();
	},
	methods:{
		async clickSave(){
			if(this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
			}
			const params={
				status:this.status,
				startDate:this.startDate,
				startTime:this.startTime,
				endDate:this.endDate,
				endTime:this.endTime,
				message:this.message,
			}

			const ret = await this.axios.post('/update-system-inf',params);
			this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,"メンテナンス情報"));

		},
		clickBack(){

		},
		async getMaintenance(){
			const ref = await this.axios.post('/get-system-inf');
			const systemInfo = ref.data.resultData.SystemInfo;
			this.message = systemInfo.message;
			this.startDate = systemInfo.mainte_start_date;
			this.endDate = systemInfo.mainte_end_date;
			this.startTime = {HH:systemInfo.mainte_start_HH, mm:systemInfo.mainte_start_MM};	
			this.endTime = {HH:systemInfo.mainte_end_HH, mm:systemInfo.mainte_end_MM};
			this.status = systemInfo.system_status.toString();	
		},
		inputCheck(){
			this.clearError();

			if(this.status == CONSTANT.KADO_STATUS_JIKAN){
				console.log("startDate="+ this.startDate);
				console.log("startTime.HH=" + this.startTime.HH);
				if(!this.startDate){
                	this.err.startDate = [MESSAGE.formatByArr(MESSAGE.E001,'開始日')];
				}
				if(this.startTime.HH==undefined || !this.startTime.HH){
					this.err.startTime = [MESSAGE.formatByArr(MESSAGE.E001,'開始時刻')];
				}

				if(!this.endDate){
                	this.err.endDate = [MESSAGE.formatByArr(MESSAGE.E001,'終了日')];
				}
				if(this.endTime.HH==undefined || !this.endTime.HH){
					this.err.endTime = [MESSAGE.formatByArr(MESSAGE.E001,'終了時刻')];
				}
			}

			if(!this.message){
					this.err.message = [MESSAGE.formatByArr(MESSAGE.E001,'メンテナンスメッセージ')];
			}

            for (let key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
		},
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
	}
}
</script>